<template>
  <div class="confirm-box">
    <nav-bar
      :isFixed="true"
      :title="$t('確認訂單')"
      :bgc="'background-color: #f8f8f8;'"
    ></nav-bar>
    <div class="content-box" :class="{ 'top-box': !isMobile() }">
      <div class="address-box" @click="gotoAddress">
        <template v-if="defaultAddress.name">
          <div class="info-box">
            <div class="name">{{ defaultAddress.name }}</div>
            <div class="phone">{{ defaultAddress.phone }}</div>
          </div>
          <div class="address-info">
            {{ spliceAddress(defaultAddress.province) }}
            {{ spliceAddress(defaultAddress.city) }}
            {{ spliceAddress(defaultAddress.district) }}
            {{ defaultAddress.street }}
            {{ defaultAddress.address }}
          </div>
        </template>
        <template v-else>
          <div style="display: flex; justify-content: center;">
            <img src="@/assets/icons/general@2x.png" style="width:20px;margin-right: 10px;" alt="" />
            {{ $t("添加地址") }}
          </div>
        </template>
      </div>
      <div class="goods-box">
        <div class="goods-info">
          <img
            class="img"
            :src="getPointsImgUrl(goodsInfo.picturePath) || defaultImage"
            @error="imgOnError($event)"
            alt=""
          />
          <div style="flex: 1">
            <div class="name-box">{{ goodsInfo.name }}</div>
            <div class="point-num">
              <div class="point">{{$t('積分')}}{{ goodsInfo.productPoints }}</div>
              <div class="num">x {{ amount }}</div>
            </div>
          </div>
        </div>
        <div class="sum-box">
          <span style="margin-right: 10px">{{$t("共")}}{{ amount }}{{ $t("件商品") }}</span>
          <span>{{$t("合計")}}：{{ sumPoints }}{{$t('積分')}}</span>
        </div>
      </div>
      <div class="message-box">
        <input
          type="text"
          v-model="remark"
          maxlength="200"
          :placeholder="$t('請填寫留言内容')"
        />
      </div>
    </div>
    <div class="footer-box">
      <div class="sum-text">{{$t("合計")}}：{{ sumPoints }}{{$t('積分')}}</div>
      <div class="btn" @click="submit">{{ $t("立即兌換") }}</div>
    </div>
  </div>
</template>
<script>
import { getPointsImgUrl } from "@/utils/util";
import { queryDetail,createOrder } from "@/api/pointsMall";
import {getAddressList} from "@/api/user.js"
export default {
  data() {
    return {
      goodsInfo: {},
      defaultImage: require("@/assets/image/default.png"),
      amount: this.$route.query.amount,
      remark: "",
      addressList:[]
    };
  },
  watch: {
    address: function(newVal,oldVal){
        if (this.address.find(item=>item.isDefault)&&!this.$store.state.user.checkAddress.id) {
          this.$store.dispatch("user/getEditAddress",this.$store.state.user.address.find(item=>item.isDefault).id);
      }
    }
  },
  computed: {
     address() {
      return this.$store.state.user.address;
    },
    defaultAddress() {
      return this.$store.state.user.checkAddress;
    },
    sumPoints() {
      return this.goodsInfo.productPoints * this.amount;
    },
  },
  async created(){
   this.$store.dispatch("user/getAddressList");
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getPointsImgUrl,
    isMobile() {
      var u = navigator.userAgent;
      const isAndroid =
        (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) &&
        u.indexOf("MMMMessenger") > -1; //android终端
      const isIos =
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
        u.indexOf("MMMMessenger") > -1; //ios终端
      return isAndroid || isIos;
    },
    async getDetail() {
      const langEnv = this.$route.query.langEnv;
      const res = await queryDetail({
        id: this.$route.query.pointsProductId,
        langEnv: langEnv?langEnv:window.localStorage.getItem("langEnv"),
      },langEnv);
      if (res.resultID === 1200) {
        this.goodsInfo = res.data;
      }
    },
    imgOnError(e) {
      e.target.src = this.defaultImage;
    },
    gotoAddress() {
      this.$router.push("/user/address?type=2");
    },
    spliceAddress(str) {
      const regex = new RegExp(`(?:${"●"})(.*)`);
      const match = (str || "").match(regex);
      return match && match[1] ? match[1] + "," : "";
    },
    async submit(){
        if(!this.defaultAddress?.id){
            return this.$toast($t("請先添加地址"))

        }
          const langEnv = this.$route.query.langEnv;
        const params={
            addressId:this.defaultAddress.id,
            pointsProductId:this.$route.query.pointsProductId,
            amount:this.amount,        
            remark:this.remark,
            langEnv:langEnv?langEnv:window.localStorage.getItem("langEnv"),
        }
       
        const res=await createOrder(params,langEnv)
        if(res.resultID === 1200){
            this.$toast(this.$t("兌換成功"))
            this.$router.push("/pointsMall/exchangeRecord")
        }
    }
  },
};
</script>
<style lang="scss" scoped>
.confirm-box {
  background: #f3f5f7;
  height: 100vh;
  .top-box {
    padding-top: 44px;
  }
  .content-box {
    .address-box {
      width: 100%;
      padding: 12px;
      margin-top: 10px;
      background: #fffaf3;
      .info-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        color: #252525;
      }
      .address-info {
        font-size: 13px;
        color: #848689;
        line-height: 17px;
        word-break: break-all;
        margin-top: 8px;
      }
    }
    .goods-box {
      padding: 10px;
      background: #fff;
      border-bottom: 1px solid #e7e7e7;
      margin-top: 10px;
      .goods-info {
        display: flex;
        // align-items: center;
        padding: 5px 10px;
        min-height: 85px;
        .img {
          width: 75px;
          height: 75px;
          margin-right: 10px;
        }
        .name-box {
          line-height: 22px;
          max-height: 44px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 控制最大行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 13px;
          line-height: 15px;
          margin-bottom: 4px;
        }
        .point-num {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .point {
            color: #f23030;
            font-size: 14px;
          }
          .num {
            color: #333;
            font-size: 13px;
          }
        }
      }
      .sum-box {
        text-align: right;
        background: #fff;
        padding: 5px 10px;
      }
    }
    .message-box {
      width: 100%;
      margin-top: 20px;
      background: #fff;
      padding: 10px;
      input {
        width: 100%;
        height: 32px;
        color: grey;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 4px;
        padding-right: 8px;
        padding-left: 8px;
        background: #eee;
        line-height: 18px;
      }
    }
  }
  .footer-box {
    z-index: 111;
    background-color: #fff;
    height: 50px;
    width: 100%;
    max-width: 640px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sum-text {
      font-size: 16px;
      color: #f23030;
      padding-left: 10px;
    }
    .btn {
      width: 37.5%;
      background: #f23030;
      line-height: 50px;
      color: #fff;
      text-align: center;
    }
  }
}
</style>
